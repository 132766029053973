<template>
    <div class="wrap">
        <section class="banner"></section>
        <section class="main">
            <div class="content-container">
                <img src="@/assets/image/default/developing.png">
                正在加速开发中...
            </div>
        </section>
    </div>
</template>
<script>
export default {
    name: 'bidding'
}
</script>
<style lang="scss" scoped>
    @import "index";
</style>